import React, { useState ,useEffect , useContext} from "react";
import { CCol, CRow, CListGroupItem, CListGroup , CSpinner} from "@coreui/react";
import { IMAGES_BASE_URL , MEDIA_URL} from "../../../config";
import {
  Route,
  Switch,
  BrowserRouter,
  Link,
  HashRouter,
  NavLink
} from "react-router-dom";
import favorite from "./favorite";
import MyProfile from "./myProfile"; 
import MyItems from "./myItems";
import { logout } from "src/components/middleware/auth";
import { useTranslation } from "react-i18next";

function Profile(props) {
  const { t } = useTranslation();
  const [showSub, setshowSub] = useState(false);
  const [showMenu, setshowMenu] = useState(false);


    useEffect(() =>   {

const section = document.querySelector( '#profileSectionContentID' );
if(section) 
{
 if( window.innerWidth < 768 ) {
  const offset = section.getBoundingClientRect().top - 30;
  window.scrollBy({ top: offset, behavior: 'smooth' });
 // console.log(section.getBoundingClientRect().top , 'in 1');
}

}

    }  , [props.location.pathname]);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="container">
      <main className="l-main">
        <div className="l-section l-section--archive">
          {/* <HashRouter> */}

            <React.Suspense>  
                
              <CRow className="profilePageAll">
                <CCol sm="2" className="profileSideBar">
                  <div className="c-avatar profile-avatar ">
                    <img
                      className="c-avatar-img"
                      src={
                        localStorage.getItem("profile") !== "undefined"
                          ? MEDIA_URL+localStorage.getItem("profile")
                          : `${IMAGES_BASE_URL}/public/uploads/members/default_avatar.jpg`
                      }
                      alt="avatar"
                    />
                  </div>
        
                <p className="profileSideBarName">  {localStorage.getItem("firstName") ? localStorage.getItem("firstName")
                    :''} {localStorage.getItem("lastName") ? localStorage.getItem("lastName")
                      :''}</p>
                  <CListGroup >
        
                      <NavLink to="/my-profile/info" className="mainTabWithSub" activeClassName="profileSideBarSelected" 
                      onClick={() =>{setshowSub(false); setshowMenu(false)}}>{t("my_profile")} 
                      </NavLink> 
                      <NavLink to="/my-profile/my-items" className="mainTabWithSub" activeClassName="profileSideBarSelected" 
                      onClick={() =>{setshowSub(false); setshowMenu(false)}}>{t("my_purchased_items")}
                      </NavLink>      

               <div className={showMenu?'showProfileDropDown':'hideProfileDropDown'}>
                                        
                    {/* <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/interest" className="mainTabWithSub" activeClassName="profileSideBarSelected">Centre d'intérêt</NavLink>
                    <NavLink  onClick={() => {setshowSub(false);}} to="/members/profile/support" className="mainTabWithSub" activeClassName="profileSideBarSelected">Support</NavLink> */}
                    <NavLink  onClick={() => {setshowSub(false);}} to="/my-profile/favorite" className="mainTabWithSub" 
                    activeClassName="profileSideBarSelected">{t("my_favorites")}</NavLink>
                  
                    <CListGroupItem className='profileSideBarLogout'>
                      <Link onClick={() => handleLogout()}
                      to="/" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>{t("logout")}</Link>
                    </CListGroupItem>
                    </div>
                  </CListGroup>
                </CCol>
                <CCol sm="10" style={{paddingRight:'5px'}} id="profileSectionContentID">
         
                  <Switch>

                    <Route
                      path="/my-profile/info"
                      component={MyProfile}
                    /> 
                    <Route
                      path="/my-profile/my-items"
                      component={MyItems}
                    /> 
                    <Route path="/my-profile/favorite" 
                    component={favorite}                       
                    />
                  </Switch>
          
                </CCol>
              </CRow>   
             
            </React.Suspense>

          {/* </HashRouter> */}
        </div>
      </main>
    </div>
  );
}

export default Profile;
